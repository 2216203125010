import { Input, Upload, Button} from 'antd';
import {  UploadOutlined } from '@ant-design/icons';
import { uploadFiles } from '../uploadFiles';

import Footer from '../../Footer';
import { useState } from "react";
const { TextArea } = Input;

const link_api = "https://us-east-1.aws.data.mongodb-api.com/app/administrador-czkas/endpoint/aliado";

const SubirAliados = () => {
    const [nombre, setNombre] = useState("");
    const [url, setUrl] = useState("");
    const [contacto, setContacto] = useState("");
    const [imagen, setImagen] = useState([]);
    const [aliadoAnadido, setAliadoAnadido] = useState(false);

    const [listaErrores, setListaErrores] = useState([]);

    function lowercaseLettersOnly(inputString) {
        const lowerCase = inputString.toLowerCase();
        const regex = /[a-z]/g;
        const lowercaseLettersArray = lowerCase.match(regex);
        return lowercaseLettersArray.join('');
      }


    const handleFinish = async (values) => {
        let errores = [];

        function validFileType(e){
            return(
            e === 'image/png' || 
            e === 'image/jpg' || 
            e === 'image/jpeg'|| 
            e === 'image/webp')
        };
        
        if(!validFileType(imagen.type)) errores.push(`Sube imagen con extension PNG, JPG, JPEG o WebP`);

        if( nombre.length === 0) errores.push("Coloca un nombre ")
        if( imagen.length === 0 ) errores.push("Coloca una imagen ")
        
        console.log(imagen)

        if(errores.length !== 0){
            setListaErrores(errores);
            return;
        }

        setListaErrores([]);
        let imagenURL = "";

        const nombreMinusculas = lowercaseLettersOnly(nombre);
        console.log(nombreMinusculas);
        try{
            imagenURL = await uploadFiles(imagen.originFileObj,"aliados", nombreMinusculas, "0");
        }catch(err){
            console.error("no se subio la imagen");
            console.error(err);
            return err;
        }   

        const document = {
            "nombre": nombre,
            "url": url,
            "contacto": contacto,
            "imagen":imagenURL,
        }

        console.log(document);

        try{
            fetch(link_api, {
                method: 'POST',
                body: JSON.stringify(document),
            })
            .then(response => response.json()) 
            .then(data => {
                if(data.error !== undefined){
                    console.error("No se subio: ", data)
                    return;
                } 
                console.log('Success:', data);
                setAliadoAnadido(true);
            })
        }catch(err){
            console.error("se subieron las imagenes a CloudFlare pero no el contenido a MongoDB");
            console.log(err);
        } 
      
    };
    
  
    return (
    <>
        <div style={{width: '80%', margin: 'auto'}}>
          <br/>
          
          <h1>Añade un nuevo aliado</h1>
            <h2>Nombre</h2>
            <TextArea 
                showCount 
                maxLength={80} 
                rows={2} 
                value={nombre} 
                onChange={(e) => setNombre(e.target.value)} 
            />

            <h2>Url</h2>
            <p>Opcional: Coloca un url que redireccione a algo perteneciente de este aliado</p>
            <TextArea 
                showCount 
                maxLength={80} 
                rows={2} 
                value={url} 
                onChange={(e) => setUrl(e.target.value)} 
            />
            <h2>Contacto</h2>
            <p>Opcional: "Correo: googleHiking@hotmail.com" o puedes colocar "numero de telefono" "8181818181" </p>
            <TextArea 
                showCount 
                maxLength={80} 
                rows={2} 
                value={contacto} 
                onChange={(e) => setContacto(e.target.value)} 
            />
            <h2>Imagen</h2>
            <Upload 
                listType="picture" 
                maxCount={1} 
                beforeUpload={() => false} 
                onChange={(e) => setImagen(e.fileList[0])}
            >
                <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>

            <Button type="primary" onClick={() => handleFinish()} >
                Submit
            </Button>

            {aliadoAnadido && (
            <div style={{backgroundColor: "lime", width: "200px", margin: "auto", borderRadius: "5px"}}>
                <h2 style={{textAlign: "center"}}>
                Aliado añadido correctamente, GRACIAS!!!
                </h2>
            </div>
            )}

            {listaErrores.length > 0 && (
            <div style={{backgroundColor: "red", width: "400px", margin: "auto", borderRadius: "5px"}}>
                <ul>
                    {listaErrores.map((error)=>(
                        <li key={error}>
                            <h4>{error}</h4>
                        </li>
                    ))}
                </ul>
            </div>
            )}
 
            </div>
          <Footer/>
      </>
      
    )
  }

export default SubirAliados