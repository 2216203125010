import { Input, Select, Upload, Checkbox, Button, DatePicker, Space } from 'antd';
import {  UploadOutlined } from '@ant-design/icons';
import { uploadFiles } from '../uploadFiles';

import Footer from '../../Footer';
import { useState } from "react";
const { Option } = Select;
const { TextArea } = Input;
const CheckboxGroup = Checkbox.Group;

const listaEquipos = ['consejo', 'administración', 'senderos', 'contenido', 'página', 'wikiloc', 'diseño', 'legal', 'eventos', 'finanzas', 'educación']

const link_api = "https://us-east-1.aws.data.mongodb-api.com/app/administrador-czkas/endpoint/miembro";

const Subirequipo = () => {
    function dateStringToTimestamp(dateString) {
    const dateObject = new Date(dateString);
    const timestamp = dateObject.getTime();
    return timestamp;
  }
    const [nombre, setNombre] = useState("");
    const [apodo, setApodo] = useState("");
    const [puesto, setPuesto] = useState("");
    const [correo, setCorreo] = useState("");
    const [equipo, setEquipo] = useState("");
    const [imagen, setImagen] = useState([]);
    const [miembroAnadido, setMiembroAnadido] = useState(false);
    const [fechaNacimiento, setFechaNacimiento] = useState(1);

    const [listaErrores, setListaErrores] = useState([]);

    function lowercaseLettersOnly(inputString) {
        const lowerCase = inputString.toLowerCase();
        const regex = /[a-z]/g;
        const lowercaseLettersArray = lowerCase.match(regex);
        return lowercaseLettersArray.join('');
      }


    const handleFinish = async (values) => {
        let errores = [];

        function validFileType(e){
            return(
            e === 'image/png' || 
            e === 'image/jpg' || 
            e === 'image/jpeg'|| 
            e === 'image/webp')
        };
        

        if( nombre.length === 0) errores.push("Coloca un nombre ")
        if( apodo.length === 0) errores.push("Coloca un apodo ")
        if( correo.length === 0 ) errores.push("Coloca el correo ")
        if( puesto.length === 0 ) errores.push("Coloca el puesto/rol ")
        if( equipo === undefined) errores.push("Coloca un equipo ")
        if( fechaNacimiento <= 0 || isNaN(fechaNacimiento)) errores.push("Coloca la fecha de nacimiento")

        if(imagen.length !== 0 && !validFileType(imagen.type)) errores.push(`Sube imagen con extension PNG, JPG, JPEG o WebP`);
        
        if(errores.length !== 0){
            setListaErrores(errores);
            return;
        }
        setListaErrores([]);
        let imagenURL = "https://staticcontent.lacumbrecotidiana.org/miembros%2FAlaska.webp";
        const nombreMinusculas = lowercaseLettersOnly(nombre);
        
        try{
            if( imagen.length !== 0 )
                imagenURL = await uploadFiles(imagen.originFileObj,"miembros", equipo, nombreMinusculas);
        }catch(err){
            console.error("no se subio la imagen");
            console.error(err);
            return err;
        }   

        const document = {
            "nombre": nombre,
            "apodo": apodo,
            "puesto": puesto,
            "correo": correo,
            "equipo": equipo,
            "fechaNacimiento": fechaNacimiento,
            "imagen":imagenURL,
        }

        try{
            fetch(link_api, {
                method: 'POST',
                body: JSON.stringify(document),
            })
            .then(response => response.json()) 
            .then(data => {
                console.log('Success:', data);
                setMiembroAnadido(true);
            })
        }catch(err){
            console.error("se subieron las imagenes a CloudFlare pero no el contenido a MongoDB");
            console.log(err);
        } 
      
    };
    
  
    return (
    <>
        <div style={{width: '80%', margin: 'auto'}}>
          <br></br>
          <br></br>
          <br></br>
          
          <h1>Añade un nuevo miembro</h1>
            <h2>Nombre</h2>
            <TextArea 
                showCount 
                maxLength={80} 
                rows={2} 
                value={nombre} 
                onChange={(e) => setNombre(e.target.value)} 
            />

            <h2>Apodo</h2>
            <p>Este es el nombre que se mostrara en la pagina</p>
            <TextArea 
                showCount 
                maxLength={80} 
                rows={2} 
                value={apodo} 
                onChange={(e) => setApodo(e.target.value)} 
            />

            <h2>Puesto</h2>
            <p>ej: Jefe finanzas, Wikiloquero, Desarrollador web, etc</p>
            <TextArea 
                showCount 
                maxLength={80} 
                rows={2} 
                value={puesto} 
                onChange={(e) => setPuesto(e.target.value)} 
            />

            <h2>Correo</h2>
            <TextArea 
                showCount 
                maxLength={80} 
                rows={2} 
                value={correo} 
                onChange={(e) => setCorreo(e.target.value)} 
            />

            <h2>Cumple años</h2>
            <DatePicker 
                onChange={(e, date) => setFechaNacimiento(dateStringToTimestamp(date))} />
            
            <h2>Equipo</h2>
            <CheckboxGroup value={equipo} options={listaEquipos} onChange={(e) => setEquipo(e[0])} />

            <h2>Imagen</h2>
            <Upload 
                listType="picture" 
                maxCount={1} 
                beforeUpload={() => false} 
                onChange={(e) => setImagen(e.fileList[0])}
            >
                <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>


            
            <br/>
            <Button type="primary" onClick={() => handleFinish()} >
                Submit
            </Button>

            {miembroAnadido && (
            <div style={{backgroundColor: "lime", width: "200px", margin: "auto", borderRadius: "5px"}}>
                <h2 style={{textAlign: "center"}}>
                Miembro añadido correctamente, GRACIAS!!!
                </h2>
            </div>
            )}

            {listaErrores.length > 0 && (
            <div style={{backgroundColor: "red", width: "400px", margin: "auto", borderRadius: "5px"}}>
                <ul>
                    {listaErrores.map((error)=>(
                        <li key={error}>
                            <h4>{error}</h4>
                        </li>
                    ))}
                </ul>
            </div>
            )}
 
            </div>
          <Footer/>
      </>
      
    )
  }

export default Subirequipo