import React from "react";

import "./footer.css";

import { Typography } from 'antd';
import { Col, Row } from 'antd';
import { Input } from 'antd';
import { Button } from 'antd';
import { InstagramFilled, FacebookFilled, YoutubeFilled, EnvironmentFilled } from '@ant-design/icons';


import Image from 'react-bootstrap/Image';

const { Title } = Typography;



const Footer = () => {
    return (
        <div>
            <div className="newsletter">
                <Row className="newsletterContent">
                    <Col span={12}>
                        <Title level={4} className="newsletterTitle" style={{color:"whitesmoke",fontWeight:"normal"}}>¡Suscríbete para recibir nuestros correos!</Title>
                    </Col>
                    <Col span={12}>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Input placeholder="Email" />
                            </Col>
                            <Col span={12}>
                                <Button type="primary" shape="round">Suscribir</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className="footer">
                <Row className="footerContent">
                    <Col span={12}>
                        <Row>
                            <Col span={8} xs={16} md={8}>
                                <p className="titleFooter">Explorar</p>
                                <p className="subTextFooter" ><a href="rutas" style={{color:"inherit"}}>Rutas</a></p>
                                <p className="subTextFooter" ><a href="quiz" style={{color:"inherit"}}>Quiz</a></p>
                                <p className="subTextFooter" ><a href="https://www.wikiloc.com/wikiloc/user.do?id=4675508" rel="noreferrer" target="_blank" style={{color:"inherit"}}>Wikiloc</a></p>
                            </Col>
                            <Col span={8} xs={16} md={8}>
                                <p className="titleFooter">Nosotros</p>
                                <p className="subTextFooter"><a href="equipo" style={{color:"inherit"}}>Nuestro equipo</a></p>
                                <p className="subTextFooter"><a href="mision" style={{color:"inherit"}}>Misión</a></p>
                            </Col>
                            <Col span={8} xs={16} md={8}>
                                <p className="titleFooter">Únete</p>
                                <p className="subTextFooter"><a href="oportunidades" style={{color:"inherit"}}>Oportunidades</a></p>
                                <p className="subTextFooter"><a href="suscribir" style={{color:"inherit"}}>Suscribir</a></p>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Image
                            src="/img/Logos/logo.webp"
                            fluid
                            alt="Logo LCC"
                            className='logobottom'
                        />
                        <p className="titleFooter">La Cumbre Cotidiana A.C.</p>
                        <p className="emailFooter"><a href="mailto:contacto@lacumbrecotidiana.org" style={{color:"inherit"}}>contacto@lacumbrecotidiana.org</a></p>
                        <Row gutter={[8, 8]} style={{width:"20vh"}}>
                            <Col span={6}>
                               <p className="subTextFooter"><a href="https://www.instagram.com/lacumbrecotidiana/"><InstagramFilled className="subTextFooter"/></a></p> 
                            </Col>
                            <Col span={6}>
                                <p className="subTextFooter"><a href="https://www.youtube.com/channel/UCT6hkV4O5gVqvnSlESH-vNg"><YoutubeFilled className="subTextFooter"/></a></p>
                            </Col>
                            <Col span={6}>
                                <p className="subTextFooter"><a href="https://www.facebook.com/Lacumbrehiking"><FacebookFilled className="subTextFooter"/></a></p>
                            </Col>
                            <Col span={6}>
                                <p className="subTextFooter"><a href="https://www.wikiloc.com/wikiloc/user.do?id=4675508"><EnvironmentFilled className="subTextFooter"/></a></p>
                            </Col>
                        </Row>
                        <p style={{color:"whitesmoke",paddingTop:"1rem"}}>
                            @Copyright 2022 La Cumbre Cotidiana A.C.
                        </p>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Footer;