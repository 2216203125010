import React from "react";
import Header from "../Header";
import Footer from "../Footer"; 

import { Parallax } from 'react-parallax';

//import {useGoogleOneTapLogin} from "react-google-one-tap-login";


const Home = () => {
/*
  useGoogleOneTapLogin({
    onSuccess: (res) => console.log(res),
    onError: (err) => console.log(err),
    googleAccountConfigs:{
      client_id: "603953117849-6dqqfr7rr77vb9fmblumvfq82796glc8.apps.googleusercontent.com"
    }
  })
*/
  

    return (
    <>
        <div>
          <Parallax
              blur={{ min: -15, max: 15 }}
              bgImage={'./img/Rutas/jumbotron.jpg'}
              bgImageAlt="Jumbotron"
              strength={-200}
          >
              
              <div style={{ height: '100vh' }}/>
          </Parallax>
          <h1>SOY HOME</h1>
        </div>

        
        <Footer />
    </>
  );
};
export default Home;

