import React from "react";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Image from 'react-bootstrap/Image';
import { useNavigate } from "react-router-dom";

import { Col, Row } from 'antd';


import './RouteCard.css';


const RouteCard = (props) => {
    //console.log(props);
    const { route } = props;
    let navigate = useNavigate ();

    //console.log(route);
    
    const tiempo = () => {
        let tiempo = "";

        if(route.minutos && route.horas){
            //convert minutes to hours
            let minutesToHours = route.minutos / 60;

            tiempo = (route.horas+minutesToHours) + " hrs";

        }
        else if(route.horas){
            tiempo = route.horas + " hrs";
        }
        else if(route.minutos){
            tiempo = route.minutos + " min";
        }
        return tiempo;
    }

    function visitRoute() {
        //console.log(route._id+" "+route.nombre);
        navigate("/rutas/"+route.url);
    }

    
    return (
        <div>
            <Card sx={{ maxWidth: 345 }}>
                <CardActionArea onClick={() => {visitRoute()}}>
                    <CardMedia
                        component="img"
                        height="300"
                        image={route.imagenPortada}
                        alt="green iguana"
                    />
                    <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {route.nombre}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {route.area}
                    </Typography>

                    <Row style={{textAlign:"center",paddingTop:"0.5rem"}}>
                        <Col span={6}>
                            <Image
                                src="./img/Íconos/altitud.png"
                                fluid
                                alt="Logo LCC"
                                className='icon'
                            />
                            {route.desnivel}
                        </Col>
                        <Col span={6}>
                            <Image
                                src="./img/Íconos/distancia.png"
                                fluid
                                alt="Logo LCC"
                                className='icon'
                            />
                            {route.distancia}km
                        </Col>
                        <Col span={6}>
                            <Image
                                src="./img/Íconos/tiempo.png"
                                fluid
                                alt="Logo LCC"
                                className='icon'
                            />
                            {tiempo()}
                            
                        </Col>
                        <Col span={6}>
                            <Image
                                src="./img/Íconos/dificultad.png"
                                fluid
                                alt="Logo LCC"
                                className='difficulty'
                            />
                        </Col>
                    </Row>

                    </CardContent>
                </CardActionArea>
            </Card>
        </div>
    );
}

export default RouteCard;