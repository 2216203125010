import {React,useEffect,useState,createRef} from "react";
import { useParams,NavLink  } from 'react-router-dom';
import { Col, Divider, Row, Input, Space, Radio, Tabs,Carousel,Modal  } from 'antd';
import { Typography } from 'antd';
import { useNavigate } from "react-router-dom";

import axios from "axios";


import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import Header from "../Header";
import Footer from "../Footer";

import SubirRutas from "../admin/aniadirRutas";

import './Ruta.css';
const Ruta = () => {

    const [ruta, setRuta] = useState({});

    const { url } = useParams();
    let navigate = useNavigate ();

    //call api to get all routes
    useEffect(() => {
        try{
        axios.get(`https://us-east-1.aws.data.mongodb-api.com/app/administrador-czkas/endpoint/ruta?url=${url}`)
                .then(res => {
                    setRuta(res.data);
                })
        }catch(error){
            console.log(error);
        }
        
    }, []);

    //function to go to external link
    const goToLink = (link) => {
        window.open(link, "_blank");
    }

    return (
        <div style={{backgroundColor:"#fafaf9"}}>
            <Header/>
                <SubirRutas />
            <Footer/>
        </div>
    );
}

export default Ruta;