import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Image from 'react-bootstrap/Image';
import { useNavigate,useLocation } from "react-router-dom";


import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import { useState } from 'react';

import MobileDrawer from './mobileDrawer';

import './Header.css';

const drawerWidth = 240;

  
const navItems = ['post','rutas','equipo', 'aliado', 'verrutas', ]; //Opciones Menu
const urlItems = ['aniadirpost','aniadirrutas', 'aniadirequipo', 'aniadiraliado', 'rutas',]; //link que te llevan las opciones de arriba
  
  
  function Header({setAdmin}){
    const [mobileOpen, setMobileOpen] = useState(false);
    
    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };
    
    let navigate = useNavigate ();
    const { pathname } = useLocation();

    const logout = ((e) =>{
      setAdmin(false);
      localStorage.setItem("jwt", "")
    })
  
    return (
      <div >
        <AppBar className={"navbar"}>
          <div className={"container"}>
            <div>
                <Image
                  width="300"
                  height="30"
                  src="/img/Logos/Letras Largo Blanco.png"
                  fluid="true"
                  alt="Logo LCC"
                  className={"logoImg"}
                />
            </div>   
              
            <div className={"innerElements"}>
              <ul>
                {navItems.map((item, key) => (
                  <li className={"li"} key={key}>=
                      <div className={"buttonNav"} onClick={(e)=> {navigate(`/${urlItems[key]}`)}}>
                        <p>
                          {item}
                        </p>
                      </div>
                  </li>
                ))}
                <li className={"li"} >
                    <div className={"buttonNav"} onClick={(e)=> {logout()}}>
                      logout
                    </div>
                </li>

              </ul>
            </div>
  
              <div className={"menuIcon"}>
                <IconButton 
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </div>
          </div>
        </AppBar>
  
          <SwipeableDrawer
            anchor='right'
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            onOpen={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <MobileDrawer />
          </SwipeableDrawer>
  
      </div>
    );
  };
  
  
  export default Header;
  