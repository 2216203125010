import { React, useEffect, useState } from "react";
import Footer from "../Footer";
import Image from 'react-bootstrap/Image';

import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import './Rutas.css';

import RouteCard from "./RouteCard";

import { Parallax } from 'react-parallax';
import { Input, Layout } from 'antd';
import { Typography } from 'antd';
import { Select } from 'antd';
import { Col, Row } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";

import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import axios from 'axios';

const link_api = process.env.REACT_APP_API_URL;

const { Title } = Typography;
const { Content } = Layout;
const { Search } = Input;

const marksKilometros = [{ value: 0, label: '0km' }, { value: 40, label: '40km', }];
const marksDesnivel = [{ value: 0, label: '0 ↑↓', }, { value: 3000, label: '3000 ↑↓', }];
const marksMsnm = [{ value: 0, label: '0 msnm' }, { value: 4000, label: '4000 msnm', }];
const marksTiempo = [{ value: 0, label: '0 Hrs' }, { value: 20, label: '20 Hrs', }];
const marksDificultadFisica = [{ value: 0, label: '0d' }, { value: 10, label: '10d', }];
const marksDificultadTecnica = [{ value: 0, label: 'A' }, { value: 6, label: 'F', }];
const marksDificultadSolar = [{ value: 1, label: 'I' }, { value: 2, label: 'II' }, { value: 3, label: 'III', }];


const Rutas = () => {

  const [optionOrden, setOptionOrden] = useState([]);
  const [orden, setOrden] = useState('nombre');
  const [ordenBool, setOrdenBool] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [showRoutes, setShowRoutes] = useState([]);

  const [optionMunicipios, setOptionMunicipios] = useState([]);
  const [selectedMunicipios, setSelectedMunicipios] = useState([]);
  const [municipio, setMunicipio] = useState([]);

  const [optionAreas, setOptionAreas] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [uploadAreas, setUploadAreas] = useState([]);
  const [area, setArea] = useState([]);

  const [optionRecorridos, setOptionRecorridos] = useState([]);
  const [selectedRecorridos, setSelectedRecorridos] = useState([]);
  const [recorrido, setRecorrido] = useState([]);

  const [distancia, setDistancia] = useState([0, 40]);
  const [desnivel, setDesnivel] = useState([0, 3000]);
  const [altutudmsnm, setAltutudmsnm] = useState([0, 4000])
  const [tiempo, setTiempo] = useState([0, 20]);
  const [nivelTecnico, setNivelTecnico] = useState([0, 7]);
  const [nivelFisico, setNivelFisico] = useState([0, 10]);
  const [nivelSolar, setNivelSolar] = useState([1, 3]);

  const [searchAvanzado, setSearchAvanzado] = useState(false);


  const background = "/img/Rutas/jumbotron.jpg";

  const insideStyles = {
    padding: 20,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    textAlign: "center",
    color: "whitesmoke !important",
  };


  const titles = {
    textTransform: "uppercase !important",
    fontFamily: 'Bebas Neue,cursive',
    fontWeight: "normal"
  };


  const { Option } = Select;

  //get routes from API with axios
  useEffect(() => {
    axios.get('https://us-east-1.aws.data.mongodb-api.com/app/administrador-czkas/endpoint/rutas')
      .then(res => {
        setRoutes(res.data);
        setShowRoutes(res.data);
      }
        )
      .catch(err => console.log(err));
  }, []);


  
  return (
    <>
        {/* Parallax */}
        <div>
          <Parallax bgImage={background} blur={{ min: -3, max: 3 }}>
            <div style={{ height: "100vh" }}>
              <div style={insideStyles}>
                <Title style={titles} className="parallax-text1">Rutas de Senderismo</Title>
                <Title style={titles} className="parallax-text" level={3}>Planea tu aventura</Title>
              </div>
            </div>
          </Parallax>
        </div>

        <Content className="contentRutas">
          {/* Titulo */}
          <div>
          <Row gutter={[16, 16]}>
          <Col span={6} xs={24} md={18}>
            <Title style={titles} className="subtitle" level={3}>Encuentra tu ruta.</Title>
          </Col>
          </Row>
          </div>

          {/*Rutas*/}
          <div className="tarjetas-rutas">
            <Row gutter={[16, 16]}>                
                {/* populate col with routecard for each route, if empty display loading animation*/}
                {
                  showRoutes>0?
                  <div style={{width:"100%"}}>
                    <p style={{textAlign:"center"}}><LoadingOutlined style={{fontSize:"40vh"}}/></p>
                  </div>
                    
                :
                
                  (showRoutes.map(route => (
                      <Col span={6} xs={24} sm={12} md={12} lg={6} key={route._id}>
                        <RouteCard route={route} />
                      </Col>
                    )))
                  
                  
                }
            </Row>
          </div>
        </Content>
        <Footer />
    </>
  );
};
export default Rutas;
