import React from 'react'
import tiposRecorridos from './tiposRecorridos';

import { Slider,InputNumber,Checkbox, Input } from 'antd';
import { Row, } from 'antd';

const marksNivelFisico = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  10: '10'
}

const marksNivelTecnico = {
  1: 'A',
  2: 'B',
  3: 'C',
  4: 'D',
  5: 'E',
  6: 'F',
  7: 'G',
}

const marksNivelSolar = {
  1: "I",
  2: "II",
  3: "III"
}

const DatosBasicos = ({
  nombre,
  setNombre,
  area,
  setArea,
  nivelFisico,
  setNivelFisico,
  nivelTecnico,
  setNivelTecnico,
  setNivelSolar,
  nivelSolar,
  litrosAgua,
  setLitrosAgua,
  municipio,
  setMunicipio,
  tipoRecorrido,
  setTipoRecorrido,
  distancia,
  setDistancia,
  desnivel,
  setDesnivel,
  altitudmsnm,
  setAltitudmsnm
}) => {
  return (
    <>
      <h2>Nombre</h2>
      <Input 
        placeholder="Pico Perico, Puerto La Papa, etc." 
        defaultValue={nombre} 
        onChange={(e) => setNombre(e.target.value)}
      />

      <h2>Donde se encuentra</h2>
      <p>Coloca el parque nacional, area natural protegida, conjunto de montañas que se encuentra, propiedad, etc.</p>
      <Input 
        placeholder="ANP Cumbres de Monterrey, Parque Nacional Cumbres de Monterrey, etc" 
        value={area} 
        onChange={(e) => setArea(e.target.value)}
      />
        
      <h2>Nivel Fisico</h2>
      <Slider marks={marksNivelFisico} 
        min={1} max={10} 
        valueLabelDisplay="auto" 
        defaultValue={nivelFisico} 
        onChange={(e) => setNivelFisico(e)}
      />

      <h2>Nivel Tecnica</h2>
      <Slider 
        marks={marksNivelTecnico} 
        min={1} max={7} 
        valueLabelDisplay="auto" 
        defaultValue={nivelTecnico} 
        onChange={(e) => setNivelTecnico(e)}
      />

      <h2>Nivel solar</h2>
      <Slider 
        marks={marksNivelSolar} 
        min={1} max={3} 
        valueLabelDisplay="auto" 
        defaultValue={nivelSolar} 
        onChange={(e) => setNivelSolar(e)}
      />

      <h2>Litros Agua</h2>
      <InputNumber 
        defaultValue={litrosAgua} 
        onChange={(e) => setLitrosAgua(e)}
      />

      <h2>Municipio</h2>
      <Input 
        placeholder="En que municipio se encuentra: Arteaga, Linares, Galeana, etc." 
        value={municipio} 
        onChange={(e) => setMunicipio(e.target.value)}
      />
       

      <h2>Tipo de recorrido</h2>
      <Checkbox.Group defaultValue={tipoRecorrido} onChange={(e) => setTipoRecorrido(e)}>
        <Row>
          {tiposRecorridos}
        </Row>
      </Checkbox.Group>

      <h2>Distancia</h2>
      <p>Longitud del hike (ida y vuelta) en kilometros</p>
      <InputNumber 
        step={.1} 
        defaultValue={distancia} 
        onChange={(e) => setDistancia(e)}
      />
        
      <h2>Desnivel</h2>
      <p>desnivel del hike en metros</p>
      <InputNumber 
        step={100}
        defaultValue={desnivel}
        onChange={(e) => setDesnivel(e)}
      />
        
      <h2>Altitud en MSNM</h2>
      <p>Metros sobre el nivel del mar del punto mas alto del sendero</p>
      <InputNumber 
        step={100} 
        defaultValue={altitudmsnm} 
        onChange={(e) => setAltitudmsnm(e)}
      />
    </>
  )
}

export default DatosBasicos