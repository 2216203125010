export const uploadFiles = async (file, folder, nombre, fileName ) => {

  const nombreSinEspacios = (nombre.toLowerCase()).replace(/[^a-z]/g, '-');
  const folderSinEspacios = (folder.toLowerCase()).replace(/[^a-z]/g, '-');
  
  try{
    fetch(`https://r2dash.contacto8173.workers.dev/${folderSinEspacios}/${nombreSinEspacios}/${fileName}`, {
      method: 'POST',
      headers: {
        'x-api-key': 'P12KGiLNBd0nuKjiVUqyK',
      },
      body: file,
      mode: 'no-cors', // enable CORS
    });

  }catch(err){
    return err;
  }
    
  return `https://staticcontent.lacumbrecotidiana.org/${folderSinEspacios}/${nombreSinEspacios}/${fileName}`;
};