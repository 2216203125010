import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Image from 'react-bootstrap/Image';
import { useNavigate,useLocation } from "react-router-dom";


import './mobileDrawer.css';

  
const navItems = ['aniadirpost','aniadirrutas','rutas',/*'aniadirequipo','aniadiraliado'*/]; //Opciones Menu
const urlItems = ['aniadirpost',/* 'Tips',*/ 'aniadirrutas'/*,'Proyectos'*/, 'rutas']; //link que te llevan las opciones de arriba

const MobileDrawer = (props) => {
    const { window } = props;
    
    
    let navigate = useNavigate ();

  

  return (
    <div className={"drawerMobile"}>
          <h3>
            <Image
              width="250"
              height="100"
              src="/img/Logos/Letras Largo Blanco.png"
              fluid="true"
              alt="Logo LCC en blanco"
              className={"logoImg"}
            />
          </h3>
        <Divider />

        {navItems.map((item, index) => (
            <div style={{ textAlign: 'left'}} key={index}>
                <div onClick={(e) => navigate(`/${urlItems[index]}`)}  style={{ textDecoration: 'none' }}>  
                    <div style={{display: "flex", padding: "10px"}}>
                        <p className={"options_mobile_drawer"}>
                          {item}
                        </p>
                  </div>
            </div></div>
        ))}
    </div>
  )
}

export default MobileDrawer