import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./components/Home";
import Rutas from "./components/Rutas";
import Ruta from "./components/Ruta";
import NotFound from "./components/NotFound";
import SubirPost from "./components/admin/anadirPost";
import SubirRutas from "./components/admin/aniadirRutas";
import Subirequipo from "./components/admin/aniadirEquipo";
import SubirAliados from "./components/admin/aniadirAliados";
import Login from "./components/login";
import ReactGA from "react-ga4";

import Header from "./components/Header"

ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });

const Router = ({setAdmin}) => {
  return (
    <>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
    <Header setAdmin={setAdmin}/>
      <Routes>

        <Route exact path="/" element={<Home/>} />
        <Route exact path="/rutas" element={<Rutas/>} />
        <Route path="/rutas/:url" element={<Ruta/>} />

        <Route path="/aniadirpost" element={<SubirPost/>} />
        <Route path="/aniadirrutas" ruta={{}} element={<SubirRutas/>} />
        <Route path="/aniadirequipo" element={<Subirequipo/>} />
        <Route path="/aniadiraliado" element={<SubirAliados/>} />

        <Route path="*" element={<NotFound/>} />
      </Routes>
    </BrowserRouter>

    </>
  );
};

export default Router;
