import React from "react";

import Footer from "../Footer";

import './NotFound.css';


const Home = () => {
    return (
    <>
        <div style={{height:"50vh"}}>
          {/*Giant 404 not found text */}
          <h1 className="notFoundText">404 Página no encontrada</h1>
          
        </div>
        <Footer/>
    </>
  );
};
export default Home;
