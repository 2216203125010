import { Row, Col, Input, Button } from "antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const ListaDeEquipo = ({listaDeEquipo, setListaDeEquipo}) => {

  const addOption = () => {
    const newOptions = [...listaDeEquipo, `Option ${listaDeEquipo.length + 1}`];
    setListaDeEquipo(newOptions);
  };

  const removeOption = (index) => {
    const newOptions = listaDeEquipo.filter((_, i) => i !== index);
    setListaDeEquipo(newOptions);
  };

  return (
    <div>
      <h2>Options Form</h2>
      <form>
        {listaDeEquipo.map((option, index) => (
            <Row key={index}>
                <Col span={20}>
                    <Input 
                        placeholder="texti" 
                        value={option}
                        onChange={(e) => {
                          const newOptions = [...listaDeEquipo];
                          newOptions[index] = e.target.value;
                          setListaDeEquipo(newOptions);
                        }}
                    />
                </Col>
                <Col span={3}/>
                <Col span={1}>
                    <MinusCircleOutlined onClick={() => removeOption(index)} />
                </Col>
                <br/>
            </Row>
        ))}
        <Button type="dashed" onClick={addOption} block icon={<PlusOutlined />}>
          Añadir Otro Item
        </Button>
      </form>
    </div>
  );
};

export default ListaDeEquipo;
