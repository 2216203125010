import { useEffect } from 'react';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Checkbox,Button, Input, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useState } from "react";
import { uploadFiles } from '../uploadFiles.jsx';
import 'suneditor/dist/css/suneditor.min.css';
import { Divider, Col, Row  } from 'antd';
import { Spin } from 'antd';
import { EditorState } from 'draft-js';
import 'draft-js/dist/Draft.css';
  
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import {
    align,
    fontColor,
    hiliteColor,
    list,
    table,
    formatBlock,
    textStyle,
    image,
  } from "suneditor/src/plugins";

  import link from 'suneditor/src/plugins/dialog/link'
  
const { TextArea } = Input;
const CheckboxGroup = Checkbox.Group;

const link_api = "https://us-east-1.aws.data.mongodb-api.com/app/administrador-czkas/endpoint/postPost";

const listaTiposDeContenidos = ['Tip', 'Informativo', 'Rutas', 'Comunidad']
const listaTiposDeTemas = ['Investigación', 'Cultura', 'Preparación', 'Técnica', 'Equipamiento', 'Rutas',
                            'Medio Ambiente', 'Vida Silvestre', 'Seguridad'];
                            
const AnadirPost = () => {
    const [cargando, setCargando] = useState(false);
    const [postListo, setPostListo] = useState(false);
    const [listaErrores, setListaErrores] = useState([]);
    const [bannerErrores,setBannerErrores] = useState(null);

    const [titulo, setTitulo] = useState("");
    const [autor, setAutor] = useState("");
    const [descripcion, setDescripcion]  = useState("");
    const [imagenPortada, setImagenPortada]  = useState([]);
    const [contenidos, setContenidos] = useState([]);
    const [referencias, setReferencias] = useState([]);
    const [tipoContenido, setTipoContenido] = useState([]);
    const [tema, setTema] = useState([]);
    const [id, setId] = useState(1);

    const [sectionStates, setSectionStates] = useState([]);

    const [miembros, setMiembros] = useState([]);

    function lowercaseLettersOnly(inputString) {
        const lowerCase = inputString.toLowerCase();
        const regex = /[a-z ]/g; 
        const lowercaseLettersArray = lowerCase.match(regex);
        const resultString = lowercaseLettersArray
          .map((char) => (char === ' ' ? '-' : char))
          .join('');
        return resultString;
      }
    useEffect(() => {
        async function fetchData() {
            try {
            const response = await fetch("https://us-east-1.aws.data.mongodb-api.com/app/administrador-czkas/endpoint/miembros");
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const responseData = await response.json();
            setMiembros(responseData);
            } catch (error) {
            console.error('Error fetching data:', error);
            }
        }
  
      // Call the async function to fetch data
      fetchData();
    }, [])
    
    
      const removeSection = (index) => {
        setSectionStates((prevState) => {
          const updatedStates = [...prevState];
          updatedStates.splice(index, 1);
          return updatedStates;
        });
      };
      
    const handleFinish = async () => {
        let errores = [];

        if( titulo.length === 0) errores.push("Coloca un titulo ")
        if( autor.length === 0) errores.push("Coloca un autor ")
        if( descripcion.length === 0) errores.push("Coloca la descripcion ")
        if( imagenPortada.length === 0 ) errores.push("Coloca la Imagen de portada ")
        if( contenidos.length === 0) errores.push("Coloca contenidos ")

        contenidos.forEach((contenido, key) => {
            const index = key+1;
            if( contenido.subtitulo.length === 0) errores.push(  `Coloca subtitulo en el contenido numero ${index}`)
            //if( contenido.descripcion.length === 0) errores.push(  `Coloca descripcion en el contenido numero ${index}`)
            if( contenido.parrafo.length <= 10) errores.push(  `Coloca parrafo en el contenido numero ${index}`)
            //if( contenido.imagen.length === 0) errores.push(  `Coloca imagen en el contenido numero ${index}`)
        });

        referencias.forEach((referencia, key) => {
            const index = key + 1;
            if( referencia.url.length === 0) errores.push(`Coloca url (link) a la referencia numero ${index}`)
            if( referencia.descripcion.length === 0) errores.push(`Coloca descripcion a la referencia numero ${index}`)
        })
        if(errores.length !== 0){
            setListaErrores(errores);
            return;
        }
        setListaErrores([]);

        const autorObjeto = miembros.find((obj) => obj.nombre === autor);

        let imagenPortadaURL = "URL DEFAULT";
        const tituloSinEspacios = lowercaseLettersOnly(titulo);
        const tituloListoParaURL = (tituloSinEspacios.toLowerCase()).replace(/\s+/g, '-');
        console.log(tituloListoParaURL);
        
        try{
            imagenPortadaURL = await uploadFiles(imagenPortada.originFileObj,"posts", tituloListoParaURL, "portada");
            console.log(imagenPortadaURL);
        }catch(err){
            console.error("no se subio la imagen de portada");
            console.error(err);
            return err;
        }   

        let listaURLs = [];
        try{
            for(let i = 0; i<contenidos.length; i++){
                if(contenidos[i].imagen[0] !== undefined)
                    listaURLs[i] = await uploadFiles(contenidos[i].imagen[0].originFileObj,"posts", tituloListoParaURL, i);
            }
            
        }catch(err){
            console.error("no se subieron las imagenes");
            console.error(err);
            return err;
        }
        let contenidosConURL = [];

        for(let i = 0 ; i<contenidos.length; i++){
            contenidosConURL.push({
                "subtitulo" : contenidos[i].subtitulo,
                "descripcion" : contenidos[i].descripcion,
                "parrafo" : contenidos[i].parrafo,
                "imagen" : listaURLs[i],
            })
        }
        const document = {
            "titulo": titulo,
            "autor": autorObjeto._id,
            "descripcion": descripcion,
            "imagenPortada": imagenPortadaURL,
            "contenidos":contenidosConURL,
            "referencias":referencias,
            "tipoContenido": tipoContenido,
            "tema":tema,
            "url":tituloListoParaURL,
        }

        try{
            fetch(link_api, {
                method: 'POST',
                body: JSON.stringify(document),
            })
            .then(response => response.json()) 
            .then(data => {
                console.log('Success:', data);
                setPostListo(true);
            })
        }catch(err){
            console.error("se subieron las imagenes a CloudFlare pero no el contenido a MongoDB");
            console.log(err);
        } 
    };
    
    useEffect(() => {
        setBannerErrores(
        <ul>
            {listaErrores.map((error)=>(
                <li key={error}>
                    <h4>{error}</h4>
                </li>
            ))}
        </ul>
        )
    }, [listaErrores])
    
    
  return (
    <>
        <br/><br/><br/>
        <Row>
            <Col span={12} offset={6}>
                <h1>Crea Un Post Nuevo</h1>
                <h2>Titulo</h2>
                <TextArea 
                    showCount 
                    maxLength={80} 
                    rows={2} 
                    value={titulo} 
                    onChange={(e) => setTitulo(e.target.value)} 
                />

                <h2>Autor</h2>
                <CheckboxGroup options={miembros.map((obj) => obj.nombre)} onChange={(e) => setAutor(e[0])} value={autor} />
    
                <h2>Descripción del post</h2>
                <TextArea 
                    showCount 
                    maxLength={500} 
                    rows={4} 
                    value={descripcion} 
                    onChange={(e) => setDescripcion(e.target.value)} 
                />
            
                <h2>Imagen de portada</h2>
                <p>Coloca una imagen con buena calidad y que sea llamativa!</p>
                <Upload 
                    listType="picture" 
                    maxCount={1} 
                    beforeUpload={() => false} 
                    onChange={(e) => setImagenPortada(e.fileList[0])}
                >
                    <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>

                <h2>Contenidos</h2>
                <p>Añade tantas secciónes como sean necesarias. Imagina cada sección como si fuera una imagen en Instagram</p>
                <div>
                {contenidos.map((contenido, index) => (
                    <div key={index}>
                        <Divider plain>Seccion {index+1}</Divider>
                        <Row>
                            <Col span={20}>
                                <Input 
                                    placeholder="Subtitulo de la sección" 
                                    value={contenido.subtitulo} 
                                    onChange={(event) => {
                                        const copiaContenidos = [...contenidos];
                                        copiaContenidos[index].subtitulo = event.target.value;
                                        setContenidos(copiaContenidos);}}
                                />
                            </Col>
                            <Col span={3} />
                            <Col span={1}>
                                <MinusCircleOutlined onClick={() => {
                                    removeSection(index);
                                    const copiaContenidos = [...contenidos];
                                    copiaContenidos.splice(index, 1);
                                    setContenidos(copiaContenidos);
                                }} />
                            </Col>

                        </Row>
                        <br/>
                        <SunEditor 
                            defaultValue="<p>hola</p>"
                            placeholder="Escribe aqui..."
                            width="100%"
                            height='auto'
                            onChange={(newEditorState) => {
                                const copiaContenidos = [...contenidos];
                                copiaContenidos[index].parrafo = newEditorState;
                                setContenidos(copiaContenidos);
                            }}
                            autoFocus={true}
                            lang="es"
                            setOptions={{
                                showPathLabel: false,
                                plugins: [
                                    align,
                                    formatBlock,
                                    fontColor,
                                    hiliteColor,
                                    list,
                                    link,
                                    table,
                                    textStyle,
                                    image
                                ],
                                buttonList: [
                                    ['undo', 'redo'],
                                    ['formatBlock', 'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                                    ['fontColor', 'hiliteColor', 'textStyle'],
                                    ['removeFormat'
                                    ,"link"],
                                    '/', 
                                    ['outdent', 'indent'],
                                    ['align', 'horizontalRule', 'list', 'lineHeight'],
                                    ['table' ], 
                                    ['fullScreen', 'showBlocks', 'codeView'],
                                    ['save', 'template'],
                                ]
                            }}
                            setContents={sectionStates[index]}
                        />
                        <div dangerouslySetInnerHTML={{ __html: contenido.contenido }}></div>
                        <Row>
                            <Col span={10}>
                            <Upload 
                                fileList={contenido.imagen}
                                listType="picture" 
                                maxCount={1} 
                                beforeUpload={() => false} 
                                onChange={(e) => {
                                    const copiaContenidos = [...contenidos];
                                    copiaContenidos[index].imagen = [e.fileList[0]];
                                    setContenidos(copiaContenidos);
                                }}
                            >
                                <Button icon={<UploadOutlined />}>Click to upload</Button>
                            </Upload>
                            </Col>
                            <Col span={14}>
                                <TextArea 
                                    placeholder="Describe la imagen: (Oso Negro En El Cerro) o (da creditos al fotografo!)"
                                    rows={2}
                                    onChange={(event) => {
                                        const copiaContenidos = [...contenidos];
                                        copiaContenidos[index].descripcion = event.target.value;
                                        setContenidos(copiaContenidos);}} 
                                />
                            </Col>
                        </Row>
                    </div>
                    ))}
                </div>
                <br/>
                <div>
                    <Button 
                        type="dashed" 
                        onClick={() => {
                            setSectionStates((prevState) => [...prevState, ""]);
                            setContenidos([...contenidos, { subtitulo: '', parrafo: '', imagen: [], descripcion: '', imagenID: id }]);
                            setId(id+1);
                        }} 
                        block 
                        icon={<PlusOutlined />}
                    >
                        Añadir Otra Seccion
                    </Button>
                </div>
                
            <h2>Referencias</h2>
            <p>Justifica el conocimiento de este post</p>
            <div>
                <Button type="dashed" onClick={() => setReferencias([...referencias, {url: "", descripcion: ""}])} block icon={<PlusOutlined />}>
                    Añadir Otra referencia
                </Button>
            </div>
            {
                referencias.map((referencia, index) => (
                    <div key={index}>
                        <Divider plain>Seccion {index+1}</Divider>
                        <Input 
                            placeholder="url de la sección" 
                            value={referencia.url} 
                            onChange={(event) => {
                                const copiaReferencias = [...referencias];
                                copiaReferencias[index].url = event.target.value;
                                setReferencias(copiaReferencias);
                            }}
                        />
                        <Row>
                            <Col span={20}>
                                <Input 
                                    placeholder="descripcion de la sección" 
                                    value={referencia.descripcion} 
                                    onChange={(event) => {
                                        const copiaReferencias = [...referencias];
                                        copiaReferencias[index].descripcion = event.target.value;
                                        setReferencias(copiaReferencias);
                                    }}
                                />
                            </Col>
                            <Col span={3}/>
                            <Col span={1}>
                                <MinusCircleOutlined 
                                    onClick={() => {
                                        const copiaReferencias = [...referencias];
                                        copiaReferencias.splice(index, 1);
                                        setReferencias(copiaReferencias);
                                    }} />
                            </Col>
                        </Row>
                    </div>
                ))
            }

            <h2>Tipo de Contenido</h2>
            <CheckboxGroup options={listaTiposDeContenidos} onChange={(e) => setTipoContenido(e)} />

            <h2>Tipo de Tema</h2>
            <CheckboxGroup options={listaTiposDeTemas} onChange={(e) => setTema(e)} />

            <br/>
            <Button type="primary" onClick={() => handleFinish()} >
                Submit
            </Button>
            </Col>
        </Row>

        {cargando && (
        <div style={{backgroundColor: "yellow", width: "200px", margin: "auto", borderRadius: "5px"}}>
            <Spin />
            <h2>
            Se estan subiendo las imagenes porfavor no cierres la pagina
            </h2>
        </div>
        )}

        {postListo && (
        <div style={{backgroundColor: "lime", width: "200px", margin: "auto", borderRadius: "5px"}}>
            <h2 style={{textAlign: "center"}}>
            Post subido correctamente, GRACIAS!!!
            </h2>
        </div>
        )}

        {bannerErrores && (
        <div style={{backgroundColor: "red", width: "400px", margin: "auto", borderRadius: "5px"}}>
            {bannerErrores}
        </div>
        )}
    </>
    
  )
}

export default AnadirPost