import { Checkbox, Col, Image } from 'antd';

// import ArnesylineaDeVida from './Advertencias/ArnesylineaDeVida.svg'
// import Basura from './Advertencias/Basura.svg'
// import Cascabel from './Advertencias/Cascabel.svg'
// import Casco from './Advertencias/Casco.svg'
// import Chorreaderos from './Advertencias/Chorreaderos.svg'
// import CuidadoConLaFauna from './Advertencias/CuidadoConLaFauna.svg'
// import CuidadoConLosPozos from './Advertencias/CuidadoConLosPozos.svg'
// import Escalar from './Advertencias/Escalar.svg'
// import ExposicionSolar from './Advertencias/ExposicionSolar.svg'
// import Hiedra from './Advertencias/Hiedra.svg'
// import Hipotermia from './Advertencias/Hipotermia.svg'
// import LamparaObligatoria from './Advertencias/LamparaObligatoria.svg'
// import Osos from './Advertencias/Osos.svg'
// import Precipicios from './Advertencias/Precipicios.svg'
// import ProhibidoFogata from './Advertencias/ProhibidoFogata.svg'
// import Puntear from './Advertencias/Puntear.svg'
// import RutaConfusa from './Advertencias/RutaConfusa.svg'
// import Servicio from './Advertencias/Servicio.svg'

/* Para añadir un icono debes:

1.- Los iconos deben añadirse a la carpeta de produccion en el otr repositorio (el live COMO SVG)

2.- Añadirlo a la carpeta de Advertencias que esta ubicada en ./Advertencias

3.- importarlo para aca

4.- añadir el nombres al array de svgs y nombres con el MISMO nombre que tiene el icono 

No encontre una manera buena de importar el array de svgs asi que si encuentras una mejor porfavor cambia el codigo

*/


// const svgs = [Precipicios, ArnesylineaDeVida, Basura, Cascabel, Casco, Chorreaderos, CuidadoConLaFauna, 
//     CuidadoConLosPozos, Escalar, ExposicionSolar, Hiedra, Hipotermia, LamparaObligatoria, Osos, Precipicios, ProhibidoFogata, Puntear, RutaConfusa, Servicio]

const nombres = ["Precipicios", "ArnesylineaDeVida", "Basura", "Cascabel", "Casco", "Chorreaderos", "CuidadoConLaFauna",
                "CuidadoConLosPozos", "Escalar", "ExposicionSolar", "Hiedra", "Hipotermia", "LamparaObligatoria",
                "Osos", "ProhibidoFogata", "Puntear", "RutaConfusa", "Servicio"]

                

let listaAdvertencias = []

for (let index = 0; index<nombres.length; index++){
    listaAdvertencias.push(
            <Col span={8}>
                <Checkbox value={`${nombres[index]}`} key={index} style={{margin:'10px', width:'100%', height:'100%', lineHeight: '32px', border: '1px solid #d9d9d9'}}>
                    <div>
                        {nombres[index]}
                    </div>
                    {/* <div>
                        <img src={svgs[index]}  style={{margin:'auto', width:'50%'}}/>
                    </div> */}
                </Checkbox>
            </Col>
        )
}
export default listaAdvertencias
