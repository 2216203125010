import React from "react";
import Router from "./Router";
import axios from "axios";

import ReactGA from "react-ga4";
import {useEffect, useState} from "react"
import Login from "./components/login";

const google = window.google
ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
function App() {

  const [admin, setAdmin] = useState(false);

  return (
    <>
    {admin ? 
      <Router setAdmin={setAdmin}/> 
        : 
      <Login setAdmin={setAdmin}/>
    }
    </>
    
  );
}

export default App;