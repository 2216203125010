import { Cascader,Switch,Slider,Rate,Radio,InputNumber,Checkbox,Button, Form, Input, Select, Space, Upload } from 'antd';
import { Col, Row } from 'antd';
const tiposRecorridos = [
    <Col span={8}>
        <Checkbox value={'Neva'} key={3} style={{margin:'10px',width:'100%', height:'100%', lineHeight: '32px', border: '1px solid #d9d9d9'}}>
            Neva
        </Checkbox>
    </Col>,
    <Col span={8}>
        <Checkbox value={'Agua'} key={3} style={{margin:'10px',width:'100%', height:'100%', lineHeight: '32px', border: '1px solid #d9d9d9'}}>
            Agua
        </Checkbox>
    </Col>,
    <Col span={8}>
        <Checkbox value={'cumbre'} key={3} style={{margin:'10px',width:'100%', height:'100%', lineHeight: '32px', border: '1px solid #d9d9d9'}}>
            cumbre
        </Checkbox>
    </Col>,
    <Col span={8}>
        <Checkbox value={'cañon'} key={3} style={{margin:'10px',width:'100%', height:'100%', lineHeight: '32px', border: '1px solid #d9d9d9'}}>
            cañon
        </Checkbox>
    </Col>,
    <Col span={8}>
        <Checkbox value={'mirador'} key={3} style={{margin:'10px',width:'100%', height:'100%', lineHeight: '32px', border: '1px solid #d9d9d9'}}>
            mirador
        </Checkbox>
    </Col>
]

export default tiposRecorridos;