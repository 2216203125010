import React from "react";
import axios from "axios";

import ReactGA from "react-ga4";
import {useEffect, useState} from "react"

import './styles.css';
const google = window.google
ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });

const Login = ({setAdmin}) => {

    const [jwt, setJwt] = useState("");
    const [mensaje, setMensaje] = useState("Hola! As login con tu correo")

    const handleApi = ((jwt)=>{
        const endpoint = `https://us-east-1.aws.data.mongodb-api.com/app/administrador-czkas/endpoint/revisarprivilegio?Bearer=${jwt}`;
        axios.get(endpoint)
        .then(res => {
          console.log(res.data.esAdmin)
          setAdmin(res.data.esAdmin)
          if(!res.data.esAdmin){
            setMensaje("no eres admin :(")
          }
        })
        .catch(err => console.log(err));
    })
  
    const handleCallbackResponse = ((response) =>{
        setJwt(response.credential);
        localStorage.setItem("jwt", response.credential)
        handleApi(response.credential);
      
    })

  useEffect(() => {
    google.accounts.id.initialize({
      client_id: "603953117849-6dqqfr7rr77vb9fmblumvfq82796glc8.apps.googleusercontent.com",
      callback: handleCallbackResponse
    })
  
    google.accounts.id.renderButton(
      document.getElementById("signInDiv"),
      {theme: "outline", size: "large"}
    );
  }, [])

  useEffect(() => {
    const jwt = localStorage.getItem("jwt");
    if(jwt !== undefined){
        //console.log("si hay cosas en la memoria!");
        handleApi(jwt);
        return;
    }
    //console.log("no hay nada en la memoria");
    
  }, [])
  
  


  return (
    <div className="container">
        <div className="cuadroEnmedio">
            <h1>Login</h1>
            <h2>{mensaje}</h2>
            <div id="signInDiv"/>
        </div>
    </div>
    
  );
}

export default Login