import React from 'react'
import listaAdvertencias from './advertencias';
import tiposRecorridos from './datosBasicos/tiposRecorridos';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Slider,Radio,InputNumber,Checkbox,Button,Divider, Input, Upload, Image } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Footer from '../../Footer';
import { useState, useEffect } from "react";
import axios from "axios";
import { uploadFiles } from '../uploadFiles.jsx';
import { Col, Row, Spin } from 'antd';
import ListaDeEquipo from './listaDeEquipo';
import DatosBasicos from './datosBasicos';
const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

//const link_api = process.env.REACT_APP_API_URL;
const link_api = "https://us-east-1.aws.data.mongodb-api.com/app/administrador-czkas/endpoint/ruta"


const listaEquipoDefault = [
  'Ganas de ir',
  'Celular con pila',
  'Manta Termica',
  'Agua',
  'Ruta Descargada',
  'Avisar a un conocido sobre esta aventura',
  'Gorra/Buff',
  'Botiquin primeros auxilios'
]

const SubirRutas = ({ruta}) => {

  const [cargando, setCargando] = useState(false);
  const [rutaLista, setRutaLista] = useState(false);
  const [mensajesErrores, setMensajesErrores] = useState([]);

  const [nombre, setNombre] = useState("");
  const [area, setArea] = useState("");
  const [nivelFisico, setNivelFisico] = useState(1);
  const [nivelTecnico, setNivelTecnico] = useState(1);
  const [nivelSolar, setNivelSolar] = useState(1);
  const [litrosAgua, setLitrosAgua] = useState(0);
  const [municipio, setMunicipio] = useState("");
  const [tipoRecorrido, setTipoRecorrido] = useState([]);
  const [distancia, setDistancia] = useState(0);
  const [desnivel, setDesnivel] = useState(0);
  const [altitudmsnm, setAltitudmsnm] = useState(0);
  const [horas, setHoras] = useState(0);
  const [minutos, setMinutos] = useState(0);
  const [advertencias, setAdvertencias] = useState([]);
  const [imagenPortada, setImagenPortada] = useState({});
  const [imagenes, setImagenes] = useState([]);
  const [comoLlegar, setComoLlegar] = useState("");
  const [comoEstacionarse, setComoEstacionarse] = useState("");
  const [costos, setCostos] = useState("");
  const [tipoVehiculo, setTipoVehiculo] = useState("Carro");
  const [dificultadFisica, setDificultadFisica] = useState("");
  const [dificultadTecnica, setDificultadTecnica] = useState("");
  const [exposicionSolar, setExposicionSolar] = useState("");
  const [trazoDeLaRuta, setTrazoDeLaRuta] = useState("");
  const [alimentosLiquidos, setAlimentosLiquidos] = useState("");
  const [vestimenta, setVestimenta] = useState("");
  const [equipoAdicional, setEquipoAdicional] = useState("");
  const [listaDeEquipo, setListaDeEquipo] = useState(listaEquipoDefault);
  const [informacionAdicional, setInformacionAdicional] = useState("");
  const [seo, setSeo] = useState("");
  const [latitud, setLatitud] = useState(0);
  const [longitud, setLongitud] = useState(0);
  const [mapaGoogle, setMapaGoogle] = useState("");
  const [linkWikiloc, setLinkWikiloc] = useState("");
  const [mapaHTMLWikiloc, setMapaHTMLWikiloc] = useState("");
  //const [status, setStatus] = useState(false);
  //const [url, setUrl] = useState("");
  const [informacionImportante, setInformacionImportante] = useState("");

  useEffect(() => {
    console.log(tipoVehiculo);
  }, [tipoVehiculo])
  

  function checkStates() {
    const messages = [];

    function validFileType(e){
      return(
      e === 'image/png' || 
      e === 'image/jpg' || 
      e === 'image/jpeg'|| 
      e === 'image/webp')
      };
    imagenes.forEach((e, index) => {
      if(!validFileType(e.imagen[0].type)) messages.push(`Sube imagen ${index+1} con extension PNG, JPG, JPEG o WebP`);
    });
      
    if( !validFileType(imagenPortada.type)) messages.push("Sube imagenes con extension PNG, JPG, JPEG o WebP");
    if (!nombre) messages.push("Nombre is empty");
    if (!area) messages.push("Area is empty");
    //if (!nivelFisico) messages.push("NivelFisico is empty");
    //if (!nivelTecnico) messages.push("NivelTecnico is empty");
    //if (!nivelSolar) messages.push("NivelSolar is empty");
    if (!litrosAgua) messages.push("LitrosAgua is empty");
    if (!municipio) messages.push("Municipio is empty");
    if (!tipoRecorrido.length) messages.push("TipoRecorrido is empty");
    if (!distancia) messages.push("Distancia is empty");
    if (!desnivel) messages.push("Desnivel is empty");
    if (!altitudmsnm) messages.push("Altitudmsnm is empty");
    if (!horas && !minutos) messages.push("Horas is empty");
    //if (!minutos) messages.push("Minutos is empty");
    if (!advertencias.length) messages.push("Advertencias is empty");
    if (!imagenPortada) messages.push("ImagenPortada is empty");
    if (!imagenes.length) messages.push("Imagenes is empty");
    if (!comoLlegar) messages.push("ComoLlegar is empty");
    if (!comoEstacionarse) messages.push("ComoEstacionarse is empty");
    if (!costos) messages.push("Costos is empty");
    if (!tipoVehiculo) messages.push("TipoVehiculo is empty");
    if (!dificultadFisica) messages.push("DificultadFisica is empty");
    if (!dificultadTecnica) messages.push("DificultadTecnica is empty");
    if (!exposicionSolar) messages.push("ExposicionSolar is empty");
    if (!trazoDeLaRuta) messages.push("TrazoDeLaRuta is empty");
    if (!alimentosLiquidos) messages.push("AlimentosLiquidos is empty");
    if (!vestimenta) messages.push("Vestimenta is empty");
    if (!equipoAdicional) messages.push("EquipoAdicional is empty");
    if (!listaDeEquipo.length >= 8) messages.push("Coloca almenos 8 cosas que deberia llevar a este hike");
    if (!informacionAdicional) messages.push("InformacionAdicional is empty");
    if (!seo) messages.push("SEO is empty");
    if (!latitud) messages.push("Latitud is empty");
    if (!longitud) messages.push("Longitud is empty");
    if (!mapaGoogle) messages.push("MapaGoogle is empty");
    if (!linkWikiloc) messages.push("LinkWikiloc is empty");
    if (!mapaHTMLWikiloc) messages.push("MapaHTMLWikiloc is empty");
    //if (!status) messages.push("Status is empty");
    //if (!url) messages.push("URL is empty");
    console.log(imagenPortada)
    return messages;
  }

  function createRutasObject() {
    const rutas = {
      "nombre": nombre,
      "area": area,
      "nivelFisico": nivelFisico,
      "nivelTecnico": nivelTecnico,
      "nivelSolar": nivelSolar,
      "litrosAgua": litrosAgua,
      "municipio": municipio,
      "tipoRecorrido": tipoRecorrido,
      "distancia": distancia,
      "desnivel": desnivel,
      "altitudmsnm": altitudmsnm,
      "horas": horas,
      "minutos": minutos,
      "advertencias": advertencias,
      "imagenPortada": imagenPortada,
      "imagenes": imagenes,
      "comoLlegar": comoLlegar,
      "comoEstacionarse": comoEstacionarse,
      "costos": costos,
      "tipoVehiculo": tipoVehiculo,
      "dificultadFisica": dificultadFisica,
      "dificultadTecnica": dificultadTecnica,
      "exposicionSolar": exposicionSolar,
      "trazoDeLaRuta": trazoDeLaRuta,
      "alimentosLiquidos": alimentosLiquidos,
      "vestimenta": vestimenta,
      "equipoAdicional": equipoAdicional,
      "informacionAdicional": informacionAdicional,
      "seo": seo,
      "latitud": latitud,
      "longitud": longitud,
      "mapaGoogle": mapaGoogle,
      "linkWikiloc": linkWikiloc,
      "mapaHTMLWikiloc": mapaHTMLWikiloc
    };
    return rutas;
  }
  const handleImagenDescripcion = (index, event) => {
    const copiaImagenes = [...imagenes];
    copiaImagenes[index].descripcion = event.target.value;
    setImagenes(copiaImagenes);
  }

  const handleImagenChange = (index, event) => {
    const copiaImagenes = [...imagenes];
    copiaImagenes[index].imagen = [event];
    setImagenes(copiaImagenes);
};
const removeSection = (index) => {
  setImagenes((prevState) => {
    const updatedStates = [...prevState];
    updatedStates.splice(index, 1);
    return updatedStates;
  });
};
  const deleteImagen = (index) => {
      removeSection(index);
      const copiaImagenes = [...imagenes];
      copiaImagenes.splice(index, 1);
      setImagenes(copiaImagenes);
  };

  const handleSubmit = async () => {
    const listaErrores = checkStates();
    if(listaErrores.length > 0){
      console.log("hay errores aun");
      console.log(listaErrores);
      setMensajesErrores(listaErrores);
      return
    }

    const values = createRutasObject();
    setCargando(true);
    const nombreSinEspacios = ((values.nombre).toLowerCase()).replace(/\s+/g, '-');

    try{
        values.imagenPortada = await uploadFiles(values.imagenPortada.originFileObj,"rutas", nombreSinEspacios, "portada");

        for(let i = 0; (values.imagenes !== undefined && i<values.imagenes.length); i++){
          values.imagenes[i].file = await uploadFiles(values.imagenes[i].imagen[0].originFileObj,"rutas", nombreSinEspacios, i);
      }

      for(let i = 0; (values.imagenes !== undefined && i<values.imagenes.length); i++){
        values.imagenes[i].imagen[0] = {};
    }

        console.log(values);

        try{
            axios.post(link_api, {values})
                .then(res => {
                    console.log(res.data);
                })
        }catch(error){
            setCargando(false);
            setMensajesErrores(["se subieron las imagenes pero no la información"]);
            console.log(error);
            return;
        }

    }catch(error){
      setCargando(false);
      setMensajesErrores(["no se subieron las imagenes"]);
        console.log(error);
        return
    }
    setCargando(false);
    setRutaLista(true);
    console.log(values);
  }
  
  return (
    <>
      <br/><br/><br/><br/><br/><br/>
      <Row>
        <Col span={12} offset={6}>
        <h1>Sube una nueva ruta</h1>
      
        <DatosBasicos 
          nombre={nombre}
          setNombre={setNombre}
          area={area}
          setArea={setArea}
          nivelFisico={nivelFisico}
          setNivelFisico={setNivelFisico}
          nivelTecnico={nivelTecnico}
          setNivelTecnico={setNivelTecnico}
          nivelSolar={nivelSolar}
          setNivelSolar={setNivelSolar}
          litrosAgua={litrosAgua}
          setLitrosAgua={setLitrosAgua}
          municipio={municipio}
          setMunicipio={setMunicipio}
          tipoRecorrido={tipoRecorrido}
          setTipoRecorrido={setTipoRecorrido}
          distancia={distancia}
          setDistancia={setDistancia}
          desnivel={desnivel}
          setDesnivel={setDesnivel}
          altitudmsnm={altitudmsnm}
          setAltitudmsnm={setAltitudmsnm}
        />

        <h1>Duracion del hike</h1>
        <h2>Horas</h2>
        <InputNumber 
          step={1} 
          defaultValue={horas} 
          onChange={(e) => setHoras(e)}
        />
        
        <h2>Minutos</h2>
        <InputNumber step={1} defaultValue={minutos} onChange={(e) => setMinutos(e)} />
        
        <h2>Advertencias</h2>
        <Checkbox.Group defaultValue={advertencias} onChange={(e) => setAdvertencias(e)}>
          <Row>
            {listaAdvertencias}
          </Row>
        </Checkbox.Group>

        <h2>Imagen de portada</h2>
        <p>Sube una imagen con buena calidad para que se anime la gente a picarle</p>
        <Upload 
            listType="picture" 
            maxCount={1} 
            onChange={(e) => setImagenPortada(e.fileList[0])}
            customRequest={dummyRequest}
        >
            <Button icon={<UploadOutlined />}>Click to upload</Button>
        </Upload>

        <h2>Imagenes</h2>
        <p>Sube varias imagenes de la ruta, mantente en un rango de entre 3 a 8 imagenes solamente</p>
        <p>Procura que sean imagenes horizontales</p>
        
          <Button type="dashed" onClick={() => setImagenes([...imagenes, {url: "", descripcion: ""}])} block icon={<PlusOutlined />}>
              Añadir Otra Imagen
          </Button>
          {
            imagenes.map((imagen, index) => (
                <div key={index}>
                    <Divider plain>Seccion {index+1}</Divider>
                    <Upload 
                        fileList={imagen.imagen}
                        listType="picture" 
                        maxCount={1} 
                        beforeUpload={() => false} 
                        onChange={(e) => handleImagenChange(index, e.fileList[0])}
                    >
                    <Button icon={<UploadOutlined />}>Click to upload</Button>
                  </Upload>
                    <Row>
                        <Col span={20}>
                            <Input 
                                placeholder="descripcion de la imagen" 
                                value={imagen.descripcion} 
                                onChange={(event) => handleImagenDescripcion(index, event)}
                            />
                        </Col>
                        <Col span={3}/>
                        <Col span={1}>
                            <MinusCircleOutlined onClick={() => deleteImagen(index)} />
                        </Col>
                    </Row>
                </div>
            ))
          }
        <h2>Como llegar</h2>
        <Input.TextArea showCount maxLength={2000} defaultValue={comoLlegar} onChange={(e) => setComoLlegar(e.target.value)}/>

        <h2>Como estacionarse</h2>
        <Input.TextArea showCount maxLength={2000} defaultValue={comoEstacionarse} onChange={(e) => setComoEstacionarse(e.target.value)}/>

        <h2>Costos</h2>
        <Input.TextArea showCount maxLength={2000} defaultValue={costos} onChange={(e) => setCostos(e.target.value)}/>

        <h2>Tipo de vehiculo</h2>
        <Radio.Group defaultValue={tipoVehiculo} onChange={(e) => setTipoVehiculo(e.target.value)}>
          <Radio.Button value="Carro">Carro</Radio.Button>
          <Radio.Button value="Camioneta">Camioneta</Radio.Button>
        </Radio.Group>

        <h2>Dificultad Fisica</h2>
        <Input.TextArea showCount maxLength={2000} defaultValue={dificultadFisica} onChange={(e) => setDificultadFisica(e.target.value)}/>

        <h2>Dificultad Tecnica</h2>
        <Input.TextArea showCount maxLength={2000} defaultValue={dificultadTecnica} onChange={(e) => setDificultadTecnica(e.target.value)}/>

        <h2>Exposicion solar</h2>
        <Input.TextArea showCount maxLength={2000} defaultValue={exposicionSolar} onChange={(e) => setExposicionSolar(e.target.value)}/>

        <h2>Trazo De La Ruta</h2>
        <Input.TextArea showCount maxLength={2000} defaultValue={trazoDeLaRuta} onChange={(e) => setTrazoDeLaRuta(e.target.value)}/>

        <h2>Alimentos y liquidos</h2>
        <Input.TextArea showCount maxLength={2000} defaultValue={alimentosLiquidos} onChange={(e) => setAlimentosLiquidos(e.target.value)}/>

        <h2>Vestimenta</h2>
        <Input.TextArea showCount maxLength={2000} defaultValue={vestimenta} onChange={(e) => setVestimenta(e.target.value)}/>

        <h2>Equipo Adicional</h2>
        <Input.TextArea showCount maxLength={2000} defaultValue={equipoAdicional} onChange={(e) => setEquipoAdicional(e.target.value)}/>

        <h2>Lista de equipo</h2>
        <ListaDeEquipo listaDeEquipo={listaDeEquipo} setListaDeEquipo={setListaDeEquipo}/>

        <h2>Informacion Adicional</h2>
        <Input.TextArea showCount maxLength={2000} defaultValue={informacionAdicional} onChange={(e) => setInformacionAdicional(e.target.value)}/>

        <h2>Descripcion muy basica de la experiencia al subir a este hike</h2>
        <Input.TextArea showCount maxLength={160} defaultValue={seo} onChange={(e) => setSeo(e.target.value)}/>

        <h2>Ubicacion Coordenadas</h2>
        
        <Row>
          <Col span={8}>
            <h3>Latitud</h3>
            <InputNumber step={.000001} style={{width: '100%'}} defaultValue={latitud} onChange={(e) => setLatitud(e)}/>
          </Col>
          <Col span={4}></Col>
          
          <Col span={8}>
            <h3>Longitud</h3>
            <InputNumber step={.000001} style={{width: '100%'}} defaultValue={longitud} onChange={(e) => setLongitud(e)}/>
          </Col>
        </Row>
        <br />
        <Image
          width={300}
          src="https://pub-f0c31b970dc7417e87eb54d70127b443.r2.dev/A-Paralax y extra/Decorativas/Dashboard/Screen Shot 2023-07-23 at 21.30.49.png"
        />
        <Image
          width={300}
          src="https://pub-f0c31b970dc7417e87eb54d70127b443.r2.dev/A-Paralax y extra/Decorativas/Dashboard/Screen Shot 2023-07-23 at 21.37.07.png"
        />
        <Divider />
        <h2>Mapa Google</h2>
        <Input placeholder="Link Google Maps para llegar al hike" defaultValue={mapaGoogle} onChange={(e) => setMapaGoogle(e.target.value)} />
        <br />
        <Image
          width={300}
          src="https://pub-f0c31b970dc7417e87eb54d70127b443.r2.dev/A-Paralax y extra/Decorativas/Dashboard/Screen Shot 2023-07-23 at 21.30.49.png"
        />
        <Image
          width={300}
          src="https://pub-f0c31b970dc7417e87eb54d70127b443.r2.dev/A-Paralax y extra/Decorativas/Dashboard/Screen Shot 2023-08-17 at 21.58.05.png"
        />
        <Divider />
        

        <h2>Link Wikiloc</h2>
        <Input placeholder="Link del Wikiloc Correspondiente" defaultValue={linkWikiloc} onChange={(e) => setLinkWikiloc(e.target.value)} />
        <Image
          width={300}
          src="https://pub-f0c31b970dc7417e87eb54d70127b443.r2.dev/A-Paralax y extra/Decorativas/Dashboard/Screen Shot 2023-07-23 at 21.41.58.png"
        />
        <Image
          width={300}
          src="https://pub-f0c31b970dc7417e87eb54d70127b443.r2.dev/A-Paralax y extra/Decorativas/Dashboard/Screen Shot 2023-07-23 at 21.42.07.png"
        />
        <Divider />

        <h2>Mapa HTML Wikiloc</h2>
        <Input placeholder="Link del HTML de Wikiloc" defaultValue={mapaHTMLWikiloc} onChange={(e) => setMapaHTMLWikiloc(e.target.value)} />
        <Image
          width={300}
          src="https://pub-f0c31b970dc7417e87eb54d70127b443.r2.dev/A-Paralax y extra/Decorativas/Dashboard/Screen Shot 2023-07-23 at 21.41.58.png"
        />
        <Image
          width={300}
          src="https://pub-f0c31b970dc7417e87eb54d70127b443.r2.dev/A-Paralax y extra/Decorativas/Dashboard/Screen Shot 2023-07-23 at 21.42.16.png"
        />
        <Image
          width={300}
          src="https://pub-f0c31b970dc7417e87eb54d70127b443.r2.dev/A-Paralax y extra/Decorativas/Dashboard/Screen Shot 2023-07-23 at 21.42.30.png"
        />   
        <Divider />

        <h2>Informacion Importante </h2>
        <p>Esto es opcional, solo si es necesario</p>
        <Input.TextArea showCount maxLength={2000}
          defaultValue={informacionImportante} onChange={(e) => setInformacionImportante(e.target.value)} 
          placeholder="Escribe algo que el senderista deba saber en especial sobre la ruta, si solo tiene agua 2 dias al año, si esta extremadamente peligrosa o alguna noticia reciente. Este apartado aparecera justo al frente" />
    

          <Button type="primary" onClick={() => handleSubmit()}>
            Subir Ruta!!!
          </Button>
        </Col>
      </Row>

      {cargando && (
        <div style={{backgroundColor: "yellow", width: "200px", margin: "auto", borderRadius: "5px"}}>
          <Spin />
          <h2>
            Se estan subiendo las imagenes porfavor no cierres la pagina
          </h2>
        </div>
      )}

      {rutaLista && (
        <div style={{backgroundColor: "lime", width: "200px", margin: "auto", borderRadius: "5px"}}>
          <h2 style={{textAlign: "center"}}>
            Ruta subida correctamente, GRACIAS!!!
          </h2>
        </div>
      )}

      {(mensajesErrores.length > 0) && (
        <div style={{backgroundColor: "red", width: "200px", margin: "auto", borderRadius: "5px"}}>
          <ul style={{textAlign: "center"}}>
            {mensajesErrores.map((mensajeError, key)=>(
              <li key={key}>
                <h3>{mensajeError}</h3>
              </li>
            ))}
          </ul>
        </div>
      )}
    <Footer/>
    </>
  )
}

export default SubirRutas